<script>
export default {
  components: {}
}
</script>

<template>
<div>
  <div class="account-pages my-5 pt-5">
    <div class="container">
        <slot />
    </div>
  </div>
</div>
</template>
